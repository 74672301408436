// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-coro-de-ninos-js": () => import("./../src/pages/coro-de-ninos.js" /* webpackChunkName: "component---src-pages-coro-de-ninos-js" */),
  "component---src-pages-coro-polifonico-js": () => import("./../src/pages/coro-polifonico.js" /* webpackChunkName: "component---src-pages-coro-polifonico-js" */),
  "component---src-pages-coros-unidos-js": () => import("./../src/pages/coros-unidos.js" /* webpackChunkName: "component---src-pages-coros-unidos-js" */),
  "component---src-pages-coros-js": () => import("./../src/pages/coros.js" /* webpackChunkName: "component---src-pages-coros-js" */),
  "component---src-pages-estudios-biblicos-js": () => import("./../src/pages/estudios-biblicos.js" /* webpackChunkName: "component---src-pages-estudios-biblicos-js" */),
  "component---src-pages-galeria-js": () => import("./../src/pages/galeria.js" /* webpackChunkName: "component---src-pages-galeria-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-nuestra-historia-js": () => import("./../src/pages/nuestra-historia.js" /* webpackChunkName: "component---src-pages-nuestra-historia-js" */),
  "component---src-pages-nuestros-templos-js": () => import("./../src/pages/nuestros-templos.js" /* webpackChunkName: "component---src-pages-nuestros-templos-js" */)
}

